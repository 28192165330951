<template>
  <div class="bigDiv">
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/03/17253567587768166.jpg"
      alt=""
      class="img"
    />
    <div class="title">
      填写下方帮你定制健康饮食方案
    </div>
    <div class="form">
      <div>
        <div class="formTit">1、{{ type1.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type1.daan"
            :key="res.value"
            @click="question[0].value = res.name"
            :class="{ formItemActive: res.name === question[0].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <div>
        <div class="formTit">2、{{ type2.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type2.daan"
            :key="res.value"
            @click="question[1].value = res.name"
            :class="{ formItemActive: res.name === question[1].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <div>
        <div class="formTit">3、{{ type3.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type3.daan"
            :key="res.value"
            @click="question[2].value = res.name"
            :class="{ formItemActive: res.name === question[2].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="form">
      <div>
        <div class="formTit">4、{{ type4.name }}</div>
        <div class="wenti">
          <div
            class="formItem"
            v-for="res in type4.daan"
            :key="res.value"
            @click="question[3].value = res.name"
            :class="{ formItemActive: res.name === question[3].value }"
          >
            {{ res.name }}
          </div>
        </div>
      </div>
    </div>

    <div class="button" @click="throttleSubmit">1元领取直播课</div>
    <!-- <div
      class="div"
      style="
      display: flex;"
    >
      <van-checkbox v-model="checked" class="checked"></van-checkbox
      ><span class="span"
        >报名即视为同意<a
          href="https://oss.hshwhkj.com/images/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF%E6%8E%88%E6%9D%83%E4%B8%8E%E4%BF%9D%E6%8A%A4%E5%A3%B0%E6%98%8E.html"
          >《个人信息保护声明》</a
        ></span
      >
    </div> -->
    <!-- <div class="divs">
      <van-field v-model="query.mobile" center clearable placeholder="请输入手机号" :formatter="formatter" style="margin-top: 10px;margin-bottom: 15px;" :maxlength="11">

      </van-field>
      <van-field v-model="code" center clearable placeholder="请输入短信验证码">
        <template #right-icon>
          <div size="small" type="primary" @click="getVerify" :class="{blue:!flag}" class="vbtn">{{flag? '获取验证码' : `${time}秒后可重新发送`}}</div>
        </template>
      </van-field>
      <div class="btn" @click="throttleSubmit">抢特价名额</div>
    </div> -->
    <!-- 第二 -->
    <!-- <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/03/172535676943053636.jpg"
      class="img"
      alt=""
    /> -->
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/03/172535678365025624.jpg"
      class="img"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/03/172535679471173499.jpg"
      class="img"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/03/172535680842417927.jpg"
      class="img"
      alt=""
    />
    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/03/172535682253939686.jpg"
      alt=""
      class="fixd img"
    />

    <img
      src="https://oss.hshwhkj.com/hshcrm/image/2024/09/10/172593477812469897.gif"
      alt=""
      class="btofixd "
      @click="throttleSubmit"
    />
    <!-- <div class="xhf">
      <transition-group name="scroll" tag="div">
        <div v-for="item in displayedItems" :key="item.id" class="scroll-item">
          <img :src="item.avatar" class="avatar" alt="头像" />
          <span class="name">{{ item.name }}</span>
        </div>
      </transition-group>
      <div class="right">
        还剩 <span>1人</span>拼成,剩余{{ formattedMinutes }}:{{
          formattedSeconds
        }}:{{ formattedMilliseconds }}
      </div>
    </div> -->
    <!-- <div class="btoDiv" @click="throttleSubmit"> -->
    <!-- <div class="btn">
        <div class="left">
          <div class="a">199元</div>
          <div class="b">单独购买</div>
        </div>
        <div class="right"><span>1</span>元拼单</div>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { getVerify, getTgUnionid, getTgUnifyPayH5 } from "../../api/components";
import { Toast } from "vant";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      checked: false,
      question: [
        {
          key: "您的工作性质?",
          value: "",
        },
        {
          key: "您是否有健康管理、调理经验?",
          value: "",
        },
        {
          key: "您在健康调理已经花费了多少?",
          value: "",
        },
        {
          key: "您希望元满帮您什么?",
          value: "",
        },
      ],
      type1: {
        name: "您的工作性质",
        daan: [
          { name: "白班", value: "1" },
          { name: "夜班", value: "2" },
          { name: "退休", value: "3" },
          { name: "其他", value: "4" },
        ],
      },
      type2: {
        name: "您是否有健康管理、调理经验",
        daan: [
          { name: "没有", value: "5" },
          { name: "自己调理", value: "6" },
          { name: "定期体检", value: "7" },
          { name: "专业营养师调理", value: "8" },
        ],
      },
      type3: {
        name: "您在健康调理已经花费了多少?",
        daan: [
          { name: "0元", value: "9" },
          { name: "100-5000", value: "10" },
          { name: "5000以上", value: "11" },
        ],
      },
      type4: {
        name: "您希望元满帮您什么",
        daan: [
          { name: "身材管理", value: "12" },
          { name: " 调理身体", value: "13" },
          { name: "增强体质 ", value: "14" },
          { name: "家庭饮食搭配", value: "15" },
        ],
      },
      type1q: "",
      type2q: "",
      type3q: "",
      type4q: "",
      query: {
        mobile: "",
      },
      memberInfo: {
        open_id: "",
      },
      code: "",
      time: 60,
      flag: true,
      ruleForm: {
        land_link: "", //落地页链接
      },

      duration: 1800000,
      remainingTime: 1800000,
      timer: null,
      items: [
        {
          id: 1,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/08/29/172492138397987206.jpg",
          name: "岁月静好",
        },
        {
          id: 2,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/08/29/17249214417744503.jpg",
          name: "过眼云烟",
        },
        {
          id: 3,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/08/30/172498641061172969.jpg",
          name: "一叶知秋",
        },
        {
          id: 4,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527282357292522.jpg",
          name: "花开富贵",
        },
        {
          id: 5,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/08/30/172498644224602809.png",
          name: "郭兰香",
        },
        {
          id: 6,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527250918842194.jpg",
          name: "周玉芳",
        },
        {
          id: 7,
          avatar:
            "https://oss.hshwhkj.com/hshcrm/image/2024/09/02/172527249412690804.jpg",
          name: "嘉真妈妈",
        },

        // 添加更多的数据项
      ],
      displayedItems: [],
      currentIndex: 0,
    };
  },
  created() {
    const code = this.$route.query.code;
    this.query.mobile = this.$route.query.mobile;
    this.code = this.$route.query.mobilecode;
    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete("mobile");
    currentUrl.searchParams.delete("mobilecode");
    currentUrl.searchParams.delete("code");
    currentUrl.searchParams.delete("state");
    this.ruleForm.land_link = currentUrl.href;
    if (code) {
      this.getTgUnionid();
      this.question = JSON.parse(sessionStorage.getItem("question"));
    }
  },
  computed: {
    formattedMinutes() {
      const minutes = Math.floor(
        (this.remainingTime % (1000 * 60 * 60)) / (1000 * 60)
      );
      return String(minutes).padStart(2, "0");
    },
    formattedSeconds() {
      const seconds = Math.floor((this.remainingTime % (1000 * 60)) / 1000);
      return String(seconds).padStart(2, "0");
    },
    formattedMilliseconds() {
      const milliseconds = Math.floor((this.remainingTime % 1000) / 10);
      return String(milliseconds).padStart(2, "0");
    },
  },
  methods: {
    initItems() {
      this.displayedItems = [this.items[0]];
    },
    startScroll() {
      this.scrollTimer = setInterval(this.scrollItems, 3000);
    },
    scrollItems() {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
      const nextItem = this.items[this.currentIndex];

      // 将新元素添加到列表中
      this.displayedItems.push(nextItem);

      // 在动画完成后移除旧元素
      // setTimeout(() => {
      this.displayedItems.shift();
      // }, 200); // 动画时间与 CSS transition 时间匹配
    },
    updateCountdown() {
      const now = Date.now();
      this.remainingTime = this.endTime - now;

      if (this.remainingTime <= 0) {
        this.remainingTime = 0;
        clearInterval(this.timer);
      }
    },
    formatter(value) {
      // 仅保留输入的数字
      return value.replace(/\D/g, "");
    },
    async getVerify() {
      const res = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (res.test(this.query.mobile)) {
        if (this.flag) {
          this.flag = false;
          const data = await getVerify(this.query);
          this.startCountdown();
          console.log(data);
        }
      } else {
        Toast.fail("请输入正确的手机号!");
      }
    },
    startCountdown() {
      if (this.timer) {
        clearInterval(this.timer); // 清除之前的定时器
      }
      this.flag = false; // 重置结束标志
      this.time = 60; // 重置时间
      this.timer = setInterval(() => {
        if (this.time <= 0) {
          clearInterval(this.timer); // 清除定时器
          this.flag = true; // 设置结束标志
          this.time = 60; // 重新设置时间
          return;
        }
        this.time -= 1; // 减少时间
      }, 1000);
    },
    timeore() {
      const times = setInterval(() => {
        if (this.time === 0) {
          this.flag = true;
          this.time = 60;

          return;
        }
        this.time = this.time - 1;
        this.timeore();
      }, 1000);
    },
    throttleSubmit() {
      const currentTime = Date.now();
      if (currentTime - this.lastClickTime < 2500) {
        return; // 在一秒内多次点击，直接返回
      }
      this.lastClickTime = currentTime; // 更新上次点击时间

      this.wxClick();
    },
    async wxClick() {
      // const res = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
      // if (res.test(this.query.mobile) && this.code !== '') {
      //   const qyeru = {
      //     mobile: this.query.mobile,
      //     mobile_code: this.code,
      //     land_link: this.ruleForm.land_link
      //   }
      console.log(this.question.length);

      for (let i = 0; i < this.question.length; i++) {
        if (this.question[i].value === "") {
          window.scrollTo(0, 150);

          return Toast("请选择您的基本信息后再进行提交~");
        }
      }
      // if (!this.checked) {
      //   window.scrollTo(0, 550);
      //   return Toast.fail("请同意用户协议后再进行报名~");
      // }
      if (this.is_weixn()) {
        this.getCode();
      } else {
        this.getTgUnifyPayH5();
      }

      // } else {
      //   window.scrollTo(0, 0)
      //   Toast.fail('请输入正确的手机号/验证码!')
      // }
    },

    is_weixn() {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i)) {
        return true;
      } else {
        return false;
      }
    },
    async getCode() {
      console.log(123, "获取code");
      sessionStorage.setItem("question", JSON.stringify(this.question));

      let scope = "snsapi_userinfo"; //手動授權
      let info = {
        appid: "wx143b46f871cc09d6",
      };

      const parsedUrl = new URL(window.location.href);
      const basAAA = parsedUrl.origin;
      const redirectUrl = basAAA;
      const { code, mobile, mobilecode, ...rest } = this.$route.query;

      // 将剩余字段转换为查询字符串
      const queryString = new URLSearchParams(rest).toString();

      console.log(123, queryString);
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
          info.appid
        }&redirect_uri=${encodeURIComponent(
          `${redirectUrl}/txBpay1Nomobile?${queryString}`
        )}&response_type=code&scope=${scope}#wechat_redirect`
      );
      // }
    },
    async getTgUnionid() {
      try {
        const query = {
          code: this.$route.query.code,
          // mobile: this.query.mobile,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
          appid: "wx143b46f871cc09d6",
        };
        const { data } = await getTgUnionid(query);
        this.memberInfo = data.data;
        console.log(data.data);
        console.log(this.memberInfo, "88");
        this.$toast.loading("正在加载,请稍等~");
        this.getTgUnifyPayH5();
      } catch (error) {
        console.log(error);
      }
    },
    async getTgUnifyPayH5() {
      try {
        const query = {
          appid: "wx143b46f871cc09d6",
          open_id: this.memberInfo.openid,
          price: this.$route.query.price || 0,
          unionid: this.memberInfo.unionid,
          headimgurl: this.memberInfo.headimgurl,
          wechat_name: this.memberInfo.nickname,
          // mobile: this.query.mobile,
          question: this.question,
          // mobile_code: this.code,
          land_link: this.ruleForm.land_link,
        };
        const res = await getTgUnifyPayH5(query);
        console.log(res, "8897879");
        this.wechatPay(res);
      } catch (error) {
        console.log(error, "我是错误");
        // this.getCode()
      }
    },

    wechatPay(res) {
      if (this.is_weixn()) {
        const {
          appId,
          timestamp,
          nonceStr,
          signature,
          paySign,
          signType,
          trade_no,
        } = res.data.data.data;

        wx.config({
          debug: false,
          appId: "wx143b46f871cc09d6",
          timestamp,
          nonceStr,
          signature,
          jsApiList: ["chooseWXPay"],
        });
        this.$toast.clear();

        wx.chooseWXPay({
          timestamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          nonceStr, // 支付签名随机串，不长于 32 位
          package: res.data.data.data.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          paySign, // 支付签名
          success: (res) => {
            console.log("成功");
            // this.isPay = true
            // this.ISshow = true
            // window.location.href = 'https://work.weixin.qq.com/ca/cawcdee1a6bfffe85c?customer_channel=zmwk_BRYtC_c_{}_p_{}'

            // 支付成功后的回调函数
            this.$router.replace({
              path: "/txTgPayOk",
              query: {
                trade_no: trade_no,
              },
            });
          },
        });
      } else {
        console.log(res.data.data.data);
        window.location.href = res.data.data.data;
      }
    },
  },
  mounted() {
    this.initItems();
    this.startScroll();
    this.endTime = Date.now() + this.duration;
    this.updateCountdown();
    this.timer = setInterval(this.updateCountdown, 10);
  },
  beforeDestroy() {
    clearInterval(this.scrollTimer);
    clearInterval(this.timer);
  },
  watch: {},

  filters: {},
};
</script>

<style scoped lang="less">
* {
  box-sizing: border-box;
}
.scroll-item {
  margin-top: 25px;
  margin-left: 20px;
  height: 100%;
}
.avatar {
  width: 25px;
  height: 25px;
  object-fit: contain;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.name {
  font-size: 14px;
  width: 100px;
  text-align-last: left;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.bigDiv {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #fff;
  .title {
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    color: #24715e;
    margin: 10px auto;
    margin-top: 20px;
  }
  .form {
    padding: 0 30px;
    .formTit {
      width: 320px;
      font-size: 16px;
      font-weight: 700;
      margin: 10px 0;
    }
    .wenti {
      display: flex;
      flex-wrap: wrap;
      .formItem {
        width: 130px;
        height: 40px;
        text-align: center;
        font-size: 15px;
        line-height: 40px;
        background-color: #f8f8f8;
        margin: 3px 10px;
        border-radius: 10px;
      }
      .formItemActive {
        background-color: #0ec9b0;
        color: #fff;
      }
    }
  }
  .button {
    width: 220px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #e71515;
    color: #fff;
    margin: 20px auto;
    font-size: 20px;
    border-radius: 50px;
    animation: pulse 0.8s infinite;
    cursor: pointer;
  }
  .div {
    width: 310px;
    margin: 0 auto;
    margin-bottom: 15px;
    .span {
      color: #666;

      a {
        color: #666;
        border-bottom: 1px solid #666;
      }
    }
    .checked {
      margin: 0 auto;
      font-size: 16px;
      color: #666;
    }
    font-size: 16px;
  }
  .img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .van-radio {
    margin: 10px 0;
  }
  .blue {
    background-color: #ccc !important;
    border: none;
  }
  .fixd {
    margin-bottom: 120px;
  }
  .divs {
    background-color: #b5f1f9;
    margin: 20px 0;
  }
  .btofixd {
    width: 375px;
    position: fixed;
    bottom: 0px;
  }
  .vbtn {
    width: 120px;
    height: 45px;
    border-radius: 8px;
    border: none;
    background-color: #0ec9b0;
    color: #fff;
    text-align: center;
    line-height: 45px;
    position: relative;
    right: -16px;
    font-size: 16px;
    font-weight: 700;
  }

  .xhf {
    width: 100%;
    height: 100%;
    overflow: hidden;

    height: 100px;
    position: fixed;
    bottom: 63px;
    // background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/08/29/172492057064514357.png");
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    align-items: center;
    .right {
      position: relative;
      top: 10px;
      left: 10px;
      font-size: 14px;
      span {
        color: red;
        font-weight: 700;
      }
    }
  }
  .btoDiv {
    width: 100%;
    text-align: center;

    height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    .btn {
      display: flex;
      height: 100px;
      text-align: center;
      align-items: center;
      .left {
        width: 120px;
        text-align: center;
        position: relative;
        top: -10px;
        .a {
          color: #666666;
          font-size: 24px;
          font-weight: 700;
        }
        .b {
          color: #ccc;
          font-size: 16px;
        }
      }
      .right {
        text-align: center;
        flex: 1;
        height: 100px;
        background: linear-gradient(to right, #ff202a, #ff650f);
        text-align: center;
        line-height: 80px;
        font-size: 36px;
        font-weight: 700;
        color: #fff;
        span {
          font-size: 62px;
          position: relative;
          top: 6px;
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .scroll-item {
    margin-top: 20px;
    margin-left: 20px;
  }
  .avatar {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .name {
    font-size: 16px;
    width: 60px;
    white-space: nowrap; /* 不换行 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 显示省略号 */
  }
  .bigDiv {
    display: flex;
    flex-direction: column;
    background-color: #ecf7e4;
    width: 750px;

    flex: 0 0 auto; /* 不缩放，保持原始大小 */
    margin: 0 auto;
    .form {
      padding: 0 30px;
      .formTit {
        width: 320px;
        font-size: 16px;
        margin: 10px 0;
      }
      .wenti {
        display: flex;
        flex-wrap: wrap;
        .formItem {
          width: 130px;
          height: 40px;
          text-align: center;
          font-size: 15px;
          line-height: 40px;
          background-color: #f8f8f8;
          margin: 3px 10px;
          border-radius: 10px;
        }
        .formItemActive {
          background-color: #0ec9b0;
          color: #fff;
        }
      }
    }
    .xhf {
      width: 750px;
      height: 100%;
      overflow: hidden;

      height: 100px;
      position: fixed;
      bottom: 120px;
      // background-image: url("https://oss.hshwhkj.com/hshcrm/image/2024/08/29/172492057064514357.png");
      background-size: contain;
      background-repeat: no-repeat;
      text-align: center;
      display: flex;
      align-items: center;
      .right {
        position: relative;
        top: 10px;
        left: 10px;
        font-size: 14px;
        span {
          color: red;
          font-weight: 700;
        }
      }
    }
    .button {
      width: 320px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #e71515;
      color: #fff;
      margin: 10px auto;
      border-radius: 50px;
      animation: pulse 0.8s infinite;
      cursor: pointer;
    }
    .btoDiv {
      width: 100%;
      text-align: center;

      height: 80px;
      background-color: #fff;
      position: fixed;
      bottom: 0;
      .btn {
        width: 750px;
        display: flex;
        height: 100px;
        text-align: center;
        align-items: center;
        .left {
          width: 120px;
          text-align: center;
          position: relative;
          top: -10px;
          .a {
            color: #666666;
            font-size: 24px;
            font-weight: 700;
          }
          .b {
            color: #ccc;
            font-size: 16px;
          }
        }
        .right {
          text-align: center;
          flex: 1;
          height: 100px;
          background: linear-gradient(to right, #ff202a, #ff650f);
          text-align: center;
          line-height: 80px;
          font-size: 36px;
          font-weight: 700;
          color: #fff;
          span {
            font-size: 62px;
            position: relative;
            top: 6px;
          }
        }
      }
    }
    .btofixd {
      width: 750px;
      position: fixed;
      bottom: 0;
    }
  }
}
</style>
<style scoped>
.scroll-container {
  overflow: hidden;
  height: 50px; /* 设置显示区域的高度 */
  position: relative;
}

.scroll-item {
  display: flex;
  align-items: center;
  height: 50px; /* 每个项的高度 */
  transition: all 1s ease; /* 设置平滑的动画效果 */
}

.scroll-enter-active,
.scroll-leave-active {
  transition: all 1s ease; /* 设置平滑的动画效果 */
}

.scroll-enter {
  transform: translateY(50px); /* 从下方进入 */
  opacity: 0;
}

.scroll-enter-to {
  transform: translateY(0); /* 进入后在原地 */
  opacity: 1;
}

.scroll-leave-active {
  position: absolute;
  width: 100%;
}

.scroll-leave-to {
  transform: translateY(-50px); /* 向上移出 */
  opacity: 0;
}
</style>
